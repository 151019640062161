export const server = "http://localhost:3009/backoffice";
export const baseUrl = "http://localhost:3009/";

const dns = window.location.host;

const { REACT_APP_DEV_COMPANY } = process.env;

const isDEV = dns.includes("localhost") || dns.includes("homologacao");

function getEndPointV2() {
  const company = REACT_APP_DEV_COMPANY ? REACT_APP_DEV_COMPANY.toLowerCase() : 'loga'

  const kongUrl = isDEV
    ? `https://sgt-homologacao.${company == 'netsul' ? 'netsulinternet' : company}.net.br/api/sgt/v1`
    : `${window.location.origin}/api/sgt/v1`

  const hashUrl = isDEV
    ? `https://ms-homologacao.${REACT_APP_DEV_COMPANY}.net.br`
    : `https://assine.${REACT_APP_DEV_COMPANY}.net.br`

  // const admappUrl = isDEV
  //   ? `https://admapp-homologacao.loga.net.br`
  //   : `https://admapp.loga.net.br`

  const admappUrl = `https://admapp-homologacao.loga.net.br`;

  const microservices = {
    attendance: `${hashUrl}/acfc1f4cde`,
    client: `${hashUrl}/5a63897a0f/loga/v1`,
    leads: `${hashUrl}/a6037ef291`,
    connectMaster: `${hashUrl}/d9a4bb8270`,
    plans: `${hashUrl}/68ea410b79`,
    massiva: `${kongUrl}/massives`,
    auth: `${kongUrl}/auth`,
    cep: `${kongUrl}/zipcode`,
    banners: `${kongUrl}/appbanners`,
    renegotiation: `${kongUrl}/manage"`,
    users: `${kongUrl}`,
    profiles: `${kongUrl}/manage`,
    userProfiles:`${kongUrl}/manage`, 
    tenants:`${kongUrl}/manage/tenants`,
    technicals:`${kongUrl}/technicals`,
    biometry:`${kongUrl}/biometry`,
    roles:`${kongUrl}/manage/roles`,
    turnos: `${kongUrl}/technicals/turnos`,
    olts: `${kongUrl}/manage/olts`,
    cities: `${admappUrl}/listar_cidades`,
    bairros: `${admappUrl}/listar_bairros`,
    fluxos: `${kongUrl}/technicals/fluxos`,
  }

  return microservices
}

export const MICRO_SERVICOS = getEndPointV2();

export const patterns = {
  phone: ["(99) 9999-9999", "(99) 9 9999-9999"],
  cep: ["99999-999"],
  cpf: ["999.999.999-99"],
  date: ["99/99/9999"],
};
