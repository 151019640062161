import { translate } from "i18n/config"

export const isEmpty = (fields, setFunctions) => {
    let valid = false

    fields.forEach((item, index) => {
        if(item.value === ''){
            valid = true
            setFunctions[index]({ ...item, error: translate("errors.requiredField") })
        }
    })

    return valid
}

export const isNumber = (fields, setFunctions) => {
    let valid = true

    fields.forEach((item, index) => {
        if(isNaN(item.value)){
            valid = false
            setFunctions[index]({ value: "", error: translate("errors.isNotANumber") })
        }
    })

    return valid
}

export const fileIsEmpty = (fields, setFunctions) => {
    let valid = false

    fields.forEach((item, index) => {
        if(item.value === "" && !item.url){
            valid = true
            setFunctions[index]({ ...item, error: translate("errors.requiredField") })
        }
    })

    return valid
}


export const isEmail = (field, setFunction) => {
  if (field.value && !field.value.match(regex.email)) {
    setFunction({...field, error: translate("errors.invalidEmail")})
    return false
  }
  return true
}

export const isPhone = (field, setFunction) => {
  if (field.value && field.originalValue.length < 10){
    setFunction({...field, error: translate("errors.invalidPhoneNumber")})
    return false
  }
  return true
}

export const isCep = (field, setFunction) => {
  if (field.value && field.originalValue.length !== 8){
    setFunction({...field, error: translate("errors.invalidCep")})
    return false
  }
  return true
}

export const isCpf = (field, setFunction) => {
  let valid = true
  let cpf = field.originalValue;
  
  let sum = 0;
  let rest;
  
  if([
    '00000000000','11111111111','22222222222','33333333333','44444444444',
    '55555555555','66666666666','77777777777','88888888888','99999999999',
  ].includes(cpf)) valid = false;
  
  let i
  for(i=1; i<=9; i++) sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if((rest === 10) || (rest === 11)) rest = 0;
  if(rest !== parseInt(cpf.substring(9, 10))) valid = false;

  sum = 0;
  for(i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i-1, i))*(12-i);
  rest = (sum * 10) % 11;

  if((rest === 10) || (rest === 11)) rest = 0;
  if(rest !== parseInt(cpf.substring(10, 11))) valid = false;

  if (!valid) setFunction({...field, error: translate("errors.invalidCpf")})
  return valid;
}

export const isDate = (field, setFunction) => {
  if (field.value && !field.value.match(regex.date)) {
    setFunction({...field, error: translate("errors.invalidDate")})
    return false
  }
  return true
}

const regex = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  name: /^[ A-Za-z]+ [A-Za-z ]+$/,
  date: /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
};