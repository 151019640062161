import axios from "axios";
import { MICRO_SERVICOS } from "../commons";

const httpClient = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "x-forwarded-host":"sgt.loga.net.br"
  },
});

const httpClient2 = axios.create({}) ;

export const get = async (url = "") => {
  try {
    const response = await httpClient.get(url);
    return successObject(response);
  } catch (error) {
    return errorObject(error);
  }
};

export const post = async (url = "", body = {}) => {
  try {
    const response = await httpClient.post(url, body);
    return successObject(response);
  } catch (error) {
    return errorObject(error);
  }
};

export const put = async (url = "", body = {}) => {
  try {
    const response = await httpClient.put(url, body);
    return successObject(response);
  } catch (error) {
    return errorObject(error);
  }
};

export const destroy = async (url = "", body) => {
  try {
    const response = await httpClient.delete(url, body);
    return successObject(response);
  } catch (error) {
    return errorObject(error);
  }
};

/**
 *
 * @param {'massiva' | 'attendance' | 'auth' | 'renegotiation' | 'users' | 'client' | 'banners' | 'profiles' | 'userProfiles'| 'tenants' | 'biometry' | 'roles' } microservice nome do micro servico em /commons.js / MICRO_SERVICOS
 * @param {'post' | 'get' | 'put' | 'delete'} method metodo da chamada
 * @param {string} endpoint (opcional)endpoint do micro-serviço
 * @param {object} body (opcional)
 */
export const requestMicroService2 = async (
  microservice,
  method,
  endpoint = "",
  body = null
) => {
  try {
    const response = await (body
      ? httpClient2[method](`${MICRO_SERVICOS[microservice]}/${endpoint}`, body)
      : httpClient2[method](`${MICRO_SERVICOS[microservice]}/${endpoint}`));

    return successObject(response);
  } catch (error) {
    console.log('error ', error);
    return {
      success: false,
      status: error?.response?.status,
      data: error?.response?.data || { message: "Houve uma falha interna" },
    };
  }
};

/**
 *
 * @param {'massiva' | 'attendance' | 'auth' | 'renegotiation' | 'users' | 'client' | 'banners' | 'profiles' | 'userProfiles'| 'tenants' | 'biometry' | 'roles' } microservice nome do micro servico em /commons.js / MICRO_SERVICOS
 * @param {'post' | 'get' | 'put' | 'delete'} method metodo da chamada
 * @param {string} endpoint (opcional)endpoint do micro-serviço
 * @param {object} body (opcional)
 */
export const requestMicroService = async (
  microservice,
  method,
  endpoint = "",
  body = null
) => {
  try {
    const response = await (body
      ? httpClient[method](`${MICRO_SERVICOS[microservice]}/${endpoint}`, body)
      : httpClient[method](`${MICRO_SERVICOS[microservice]}/${endpoint}`));

    return successObject(response);
  } catch (error) {
    return {
      success: false,
      status: error?.response?.status,
      data: error?.response?.data || { message: "Houve uma falha interna" },
    };
  }
};

const successObject = (response) => {
  return {
    success: true,
    status: response.status,
    data: response.data?.data || response.data,
  };
};

const errorObject = (error) => {
  return {
    success: false,
    status: error.request.status,
    errors: errorsResponse(error),
  };
};

const errorsResponse = (error) => {
  try {
    const response = JSON.parse(error.request.response);
    return response.errors;
  } catch {
    return { error: undefined };
  }
};

export const setDefaultHeaders = (token) => {
  if (token) {
    httpClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete httpClient.defaults.headers.common["Authorization"];
  }
};
