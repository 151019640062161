import { format } from "date-fns";
import { isEmpty } from "helpers/validateFields";
import { requestMicroService } from "./http";

export const listMassives = async (
  olt,
  causeId,
  responsibleId,
  openingType,
  startDate,
  endDate,
  serial
) => {
  if (
    (olt === undefined,
    causeId === undefined,
    responsibleId === undefined,
    openingType === undefined,
    startDate === undefined,
    endDate === undefined)
  ) {
    const response = await requestMicroService("massiva", "get", "");

    return response;
    
  } else {
    const response = await requestMicroService(
      "massiva",
      "get",
      `?olt=${olt}&responsavel_id=${responsibleId}&causa_id=${causeId}&tipoAbertura=${openingType}&startDate=${startDate}&endDate=${endDate}&serial=${serial}
    `
    );

    return response;
  }
};

export const showMassive = async (masssiveId) => {
  const response = await requestMicroService("massiva", "get", `${masssiveId}`);

  return response;
};

export const searchMassive = async (params) => {
  const response = await requestMicroService("massiva", "get", params);

  return response;
};

export const finallizeMassive = async (masssiveId) => {
  const response = await requestMicroService(
    "massiva",
    "put",
    `status/finish/${masssiveId}`,
    {
      horario_fim: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    }
  );

  return response;
};

export const changePrevisaoRetorno = async (
  masssiveId,
  previsao_retorno_confirmada
) => {
  const response = await requestMicroService(
    "massiva",
    "put",
    `previsao_retorno/${masssiveId}`,
    {
      previsao_retorno_confirmada,
    }
  );

  return response;
};

export const ignoreMassive = async (masssiveId) => {
  const response = await requestMicroService(
    "massiva",
    "put",
    `status/ignore/${masssiveId}`,
    {
      massiva_status: "I",
    }
  );

  return response;
};

export const confirmeMassive = async (masssiveId) => {
  const response = await requestMicroService(
    "massiva",
    "put",
    `status/confirme/${masssiveId}`,
    {
      massiva_status: "I",
    }
  );

  return response;
};

export const updateMassive = async (masssiveId, massive) => {
  const response = await requestMicroService(
    "massiva",
    "put",
    `${masssiveId}`,
    massive
  );

  return response;
};

export const listResponsibles = async () => {
  const response = await requestMicroService("massiva", "get", "responsibles");

  return response;
};

export const listCauses = async () => {
  const response = await requestMicroService("massiva", "get", "causes");

  return response;
};

export const registerCause = async (cause) => {
  const response = await requestMicroService(
    "massiva",
    "post",
    "causes",
    cause
  );

  return response;
};

export const updateCause = async (cuaseId, cause) => {
  const response = await requestMicroService(
    "massiva",
    "put",
    `causes/${cuaseId}`,
    cause
  );

  return response;
};

export const destroyCause = async (cuaseId) => {
  const response = await requestMicroService(
    "massiva",
    "delete",
    `causes/${cuaseId}`
  );

  return response;
};

export const listOlts = async () => {
  const response = await requestMicroService("massiva", "get", "olts");

  return response;
};

export const registerMassive = async (massive) => {
  const response = await requestMicroService("massiva", "post", "", massive);

  return response;
};

export const deleteMassive = async (massiveId) => {
  const response = await requestMicroService(
    "massiva",
    "delete",
    `${massiveId}`
  );

  return response;
};
