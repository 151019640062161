import React from "react";
import {
  CToast,
  CToaster,
  CToastHeader,
  CToastBody,
} from "@coreui/react";

const Toast = ({toasts}) => {
  const toasters = (() => {
    return toasts.reduce((toasters, toast) => {
      toasters[toast.position] = toasters[toast.position] || [];
      toasters[toast.position].push(toast);
      return toasters;
    }, {});
  })();

  return (
    <>
      {Object.keys(toasters).map((toasterKey) => (
        <CToaster position={toasterKey} key={"toaster" + toasterKey}>
          {toasters[toasterKey].map((toast, key) => {
            return (
              <CToast
                key={"toast" + key}
                show={true}
                autohide={toast.autohide}
                fade={toast.fade}
              >
                <CToastHeader closeButton={toast.closeButton}>
                  {toast.title}
                </CToastHeader>
                <CToastBody>{toast.text}</CToastBody>
              </CToast>
            );
          })}
        </CToaster>
      ))}
    </>
  );
};
export default Toast;
