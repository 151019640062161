import React, { createContext, useState, useContext } from "react";
import { listMassives } from "services/massivas";

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [show, setShow] = useState("responsive");
  const [toasts, setToasts] = useState([]);
  const [massive, setMassive] = useState({
    finalized: [],
    detected: [],
    confirmed: [],
  });

  const [renegotiationRules, setRenegotiationRules] = useState([]);
  const [loading, setLoading] = useState({});
  const [systemUsers,setSystemUsers] = useState([]);
  const navigation = [
    {
      _tag: "CSidebarNavDropdown",
      name: "Telefonia móvel",
      icon: null,
      _children: [
        {
          _tag: "CSidebarNavDropdown",
          name: "Logística",
          icon: null,
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "Envio de simcard",
              to: "/mobile-phone/logistics/simcard",
              icon: null,
            },
          ],
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Financeiro",
      icon: null,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Renegociar",
          to: "/renegotiation",
          icon: null,
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Comercial",
      icon: null,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Central de Vendas",
          icon: null,
          to: "/crm",
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Marketing",
      icon: null,
      _children: [
        {
          _tag: "CSidebarNavDropdown",
          name: "Aplicativo",
          icon: null,
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "Imagens",
              to: "/aplicativo/images",
              icon: null,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Cadastro de imagem",
              to: "/aplicativo/images/create",
              icon: null,
            },
          ],
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Gerenciar",
      icon: null,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Usuários",
          to: "/users",
          icon: null,
        },
        {
          _tag: "CSidebarNavItem",
          name: "Técnicos",
          to: "/technical",
          icon: null,
        },
        {
          _tag: "CSidebarNavItem",
          name: "WorkZones",
          to: "/workzones",
          icon: null,
        },
        {
          _tag: "CSidebarNavItem",
          name: "Turnos",
          to: "/turnos",
          icon: null,
        },
        {
          _tag: "CSidebarNavItem",
          name: "Fluxos",
          to: "/fluxos",
          icon: null,
        },
        {
          _tag: "CSidebarNavDropdown",
          name: "Perfis",
          icon: null,
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "Criação de perfil",
              to: "/perfis",
              icon: null,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Associação de perfil",
              to: "/associar-perfil",
              icon: null,
            },
          ],
        },
      ]
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Massivas",
      icon: null,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Lista de Massivas",
          to: "/massivas",
          icon: null,
        },
        {
          _tag: "CSidebarNavItem",
          name: "Causas",
          to: "/massivas/causes",
          icon: null,
        },
        
      ],
    },
    {
      _tag: "CSidebarNavItem",
      name: "Vendas Biometria",
      to: "/biometrias-reprovadas",
      icon: null,
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Vendas Biometria",
          to: "/biometrias-reprovadas",
          icon: null,
        },
      ],
    },
  ];
  const [rolesToUpdate, setRoles] = useState([]);

  const addRoles = (roles) => {
    setRoles((prevState) => [...prevState, roles]);
  };

  const addToaster = (toast) => {
    setToasts([
      ...toasts,
      {
        position: "top-right",
        autohide: 2000,
        ...toast,
      },
    ]);
  };

  const fetchMassive = async (olt, causeId, responsibleId, openingType, startDate, endDate, serial) => {
    setLoading((old) => ({ ...old, massives: true }));
    try {
      const { data, success } = await listMassives(olt, causeId, responsibleId, openingType, startDate, endDate, serial);
  
      if (!success) {
        throw new Error(data.message);
      }

      setMassive(() => {
        const detected = data.filter((item) => item.massiva_status === "A");
        const confirmed = data.filter((item) => item.massiva_status === "C");
        const finalized = data.filter((item) => item.massiva_status === "E");

        return {
          detected,
          finalized,
          confirmed,
        };
      });
    } catch (err) {
      addToaster({
        title: "Erro",
        text: err.message,
      });
    }
    setLoading((old) => ({ ...old, massives: false }));
  };

  return (
    <StateContext.Provider
      value={{
        user,
        setUser,
        show,
        setShow,
        addToaster,
        toasts,
        massive,
        loading,
        renegotiationRules,
        setRenegotiationRules,
        fetchMassive,
        systemUsers,
        setSystemUsers,
        navigation,
        addRoles,
        rolesToUpdate,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(StateContext);
  const {
    user,
    setUser,
    show,
    setShow,
    addToaster,
    toasts,
    massive,
    loading,
    renegotiationRules,
    setRenegotiationRules,
    fetchMassive,
    systemUsers,
    setSystemUsers,
    navigation,
    addRoles,
    rolesToUpdate,
  } = context;

  return {
    user,
    setUser,
    show,
    setShow,
    addToaster,
    toasts,
    massive,
    loading,
    renegotiationRules,
    setRenegotiationRules,
    fetchMassive,
    systemUsers,
    setSystemUsers,
    navigation,
    addRoles,
    rolesToUpdate,
  };
};
